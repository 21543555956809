<template>
  <div
    class="userservice"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 搜索 -->
    <div class="search">
      <span class="span1">服务时间</span>
      <el-date-picker
        v-model="searchs.time"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        @change="aa"
      >
      </el-date-picker>
      <span class="span1" style="margin-left: 8%">客服工号</span>
      <el-input v-model="searchs.jobNumber" style="width: 10%"></el-input>
      <img
        src="../../assets/images/query.png"
        alt=""
        style="margin-left: 3%; width: 49px"
        @click="searchList"
      />
    </div>
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="workNumber" label="客服工号"></el-table-column>
        <el-table-column
          prop="userName"
          label="客服姓名"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="dayDate"
          label="日期"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="loginDate"
          label="当日首次登录"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="onlineTimeStr"
          label="当日累计在线"
          width="120"
        ></el-table-column>
        <el-table-column prop="freeTimeStr" label="空闲时长"></el-table-column>
        <el-table-column prop="busyTimeStr" label="忙碌时长"></el-table-column>
        <el-table-column prop="leaveTimeStr" label="暂离时长"></el-table-column>
        <el-table-column
          prop="answerAvgStr"
          label="平均响应速度"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="missCount"
          label="当日漏接次数"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="callCount"
          label="当日通话次数"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="callTimeStr"
          label="当日通话时长"
          width="120"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 总结 -->
    <div class="total">
      <div class="left">
        <p>
          总计在线时长：
          <span class="spanto">{{ totalTime }}</span>
        </p>
        <p>
          总平均响应时间：
          <span class="spanto">{{ avgAnswer }}</span
          >秒
        </p>
      </div>
      <!-- 分页 -->
      <div class="right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="pageCount"
          layout="total, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="buttons">
      <el-button>数据分析</el-button>
      <el-button @click="exportHis">数据导出</el-button>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      searchs: {
        time: "",
        jobNumber: "",
      },
      // 分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      // 搜索
      input: "",
      // 下拉
      value: "",
      //   表格
      tableData: [],
      totalTime: "",
      avgAnswer: "",
      timeArry: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    exportHis() {
      let that = this;
      that
        .$http({
          url: "/too/center/callCenterHistory/exportHotlineList",
          params: {
            serviceType: 3,
            startTime: this.timeArry == null ? "" : this.timeArry[0],
            endTime: this.timeArry == null ? "" : this.timeArry[1],
            workNumber: this.searchs.jobNumber,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log("导出400记录：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "400记录" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    aa(val) {
      console.log("val:", val);
      this.timeArry = val;
    },
    searchList() {
      apiShout
        .getfourSta({
          serviceType: 3,
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startTime: this.timeArry == null ? "" : this.timeArry[0],
          endTime: this.timeArry == null ? "" : this.timeArry[1],
          workNumber: this.searchs.jobNumber,
        })
        .then((res) => {
          // console.log("400中心：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.totalTime = res.data.result.totalTime;
            this.avgAnswer = res.data.result.avgAnswer;
          }
        });
    },
    getList() {
      apiShout
        .getfourSta({
          serviceType: 3,
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startTime: this.timeArry == null ? "" : this.timeArry[0],
          endTime: this.timeArry == null ? "" : this.timeArry[1],
          workNumber: this.searchs.jobNumber,
        })
        .then((res) => {
          // console.log("400中心：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.totalTime = res.data.result.totalTime;
            this.avgAnswer = res.data.result.avgAnswer;
          }
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageCount = val;
      this.getList();
    },
    // 弹框详情
    details() {},
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
  padding: 0 10px;
}

.userservice {
  .search {
    height: 80px;
    margin: 0 40px;
    margin-left: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .span1 {
      display: block;
      font-size: 14px;
      width: auto;
      color: #909399;
      margin-right: 1%;
      font-weight: bold;
    }
    .el-select {
      margin-right: 60px;
      margin-left: 10px;
    }
    .el-input {
      width: 120px;
      margin-left: 10px;
    }
    .in1 {
      margin-right: 10px;
    }
    .in2 {
      margin-right: 60px;
    }
  }
  // table
  .tables {
    margin-left: 25px;
    margin-right: 40px;
    .el-table::before {
      height: 0;
    }
    .el-table {
      td,
      th.is-leaf {
        border: none !important;
      }
      tr th {
        font-size: 19px;
        color: #a8a8a8;
        text-align: center;
      }
      tr td {
        text-align: center;
        font-size: 17px;
        color: #a8a8a8;
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  // 弹框
  .el-dialog {
    border-radius: 18px;
  }
  .el-dialog__header {
    border-radius: 18px 18px 0 0;
  }
  .el-dialog__close {
    color: transparent;
    background-image: url("../../assets/images/close.png");
    background-repeat: no-repeat;
    background-size: 14px;
  }
  .el-dialog__body {
    border-radius: 0 0 18px 18px;
    padding: 10px 20px 0 20px;
  }
  // 总结
  .total {
    display: flex;
    justify-content: space-between;
    margin-left: 85px;
    margin-top: 20px;
    flex-wrap: wrap;
    .right {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .el-pagination {
        padding: 0;
        .el-pager,
        .el-pager li {
          color: #afafaf;
          font-size: 17px;
          font-weight: normal;
        }
        .el-pager li.active {
          color: #d78673;
        }
        .el-pager li:hover {
          color: #d78673;
        }
        img {
          display: inline-block;
        }
        .lefts {
          margin-right: 25px;
        }
        .rights {
          float: right;
          margin-left: 25px;
        }
      }
    }
    p {
      font-size: 15px;
      display: inline-block;
      margin-right: 80px;
      color: #606266;
    }
  }
  // 按钮
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    .el-button {
      width: 130px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      margin-top: 20px;
      margin-right: 20px;
      background: #d78673;
      color: #ffffff;
      font-size: 16px;
      padding: 0px;
    }
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>